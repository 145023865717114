















import { Component, Vue, Prop } from 'vue-property-decorator'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'

@Component({
  components: {
    DropdownSelect
  }
})

export default class UpdateAppVersionDropdown extends Vue {
  @Prop({default: false}) private required!: boolean
  @Prop({default: 'select'}) private label!: string
}
