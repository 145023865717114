import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { UpdateAppVersionPresenter } from '@/app/ui/presenters/UpdateAppVersionPresenter'
import {
  UpdateAppVersionPayloadInterface,
  UpdateAppVersionRequest,
} from '@/data/payload/api/UpdateAppVersionRequest'
import { paramsInterface } from '@/app/infrastructures/misc/Constants/updateAppVersion'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'
import {
  UpdateAppVersion,
  UpdateAppVersions,
} from '@/domain/entities/UpdateAppVersion'

export interface UpdateAppVersionState {
  isLoading: boolean
  isSave: boolean
  isErrorAuthorization: boolean
  successCancel: boolean
  paginationData: Pagination
  listData: UpdateAppVersion[]
  detailData: UpdateAppVersion | null
}

@Module({ namespaced: true, store, name: 'update-app-version', dynamic: true })
class UpdateAppVersionController extends VuexModule
  implements UpdateAppVersionState {
  private presenter: UpdateAppVersionPresenter = container.resolve(
    UpdateAppVersionPresenter
  )
  isLoading = false
  isSave = false
  successCancel = false
  isErrorAuthorization = false
  paginationData: Pagination = new Pagination()
  listData: UpdateAppVersion[] = []
  detailData: UpdateAppVersion | null = null

  @Action({ rawError: true })
  public getAll(params: paramsInterface) {
    this.setLoading(true)
    this.setErrorAuthorization(false)

    if (params.sortBy === undefined || params.sortBy === '') {
      params.sortBy = 'latest_created'
    }

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setListData(res)
      })
      .catch(err => {
        if (err?.status === 403) {
          this.setErrorAuthorization(true)
        }
        Vue.notify({
          title: 'Fetch List Update App Version Failed',
          text:
            err?.status === 400 ? err?.error?.message?.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getOne(id: number) {
    this.setLoading(true)
    this.setDetailData(null)

    this.presenter
      .getOne(id)
      .then(res => {
        this.setDetailData(res)
      })
      .catch(err => {
        Vue.notify({
          title: 'Fetch Detail Update App Version Failed',
          text:
            err?.status === 400 ? err?.error?.message?.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public create(payload: UpdateAppVersionPayloadInterface): void {
    this.setLoading(true)
    this.setSave(false)

    this.presenter
      .create(
        new UpdateAppVersionRequest(
          payload.operationSystem,
          payload.updateType,
          payload.appVersion,
          payload.versionValidation,
          payload.publishDateTime
        )
      )
      .then(() => {
        this.setSave(true)
      })
      .catch(error => {
        this.setSave(false)
        Vue.notify({
          title: 'Create Update App Version Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public update(form: {
    id: number
    payload: UpdateAppVersionPayloadInterface
  }): void {
    this.setLoading(true)
    this.setSave(false)

    this.presenter
      .update(
        form.id,
        new UpdateAppVersionRequest(
          form.payload.operationSystem,
          form.payload.updateType,
          form.payload.appVersion,
          form.payload.versionValidation,
          form.payload.publishDateTime
        )
      )
      .then(() => {
        this.setSave(true)
      })
      .catch(error => {
        this.setSave(false)
        Vue.notify({
          title: 'Save Update App Version Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public cancel(id: number) {
    this.setLoading(true)
    this.setSuccessCancel(false)

    this.presenter
      .cancel(id)
      .then(() => {
        this.setSuccessCancel(true)
      })
      .catch(err => {
        this.setSuccessCancel(false)
        Vue.notify({
          title: 'Cancel Update App Version Failed',
          text:
            err?.status === 400 ? err?.error?.message?.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setListData(data: UpdateAppVersions) {
    this.paginationData = data.pagination
    this.listData = data.data
  }

  @Mutation
  public setDetailData(data: UpdateAppVersion | null) {
    this.detailData = data
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  public setSave(bool: boolean) {
    this.isSave = bool
  }

  @Mutation
  private setErrorAuthorization(bool: boolean) {
    this.isErrorAuthorization = bool
  }

  @Mutation
  public setSuccessCancel(bool: boolean) {
    this.successCancel = bool
  }
}

export default getModule(UpdateAppVersionController)
