

























import { Component, Vue, Prop } from 'vue-property-decorator'
import IconCalender from '@/app/ui/assets/icon_calendar.vue'
import IconClock from '@/app/ui/assets/icon_clock.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'

@Component({
  components: { IconClock, IconCalender, TextInput },
})
export default class UpdateAppVersionInput extends Vue {
  @Prop({ default: false }) private required!: boolean
  @Prop({ default: 'label' }) private label!: string
  @Prop({ default: '' }) private errorMessage!: string
  @Prop({ default: '' }) private fieldDescription!: string
}
