import { UpdateAppVersionRequestInterface } from '@/data/payload/contracts/UpdateAppVersionRequest'

export interface UpdateAppVersionPayloadInterface {
  operationSystem: string
  updateType: string
  appVersion: string
  versionValidation: string
  publishDateTime: string
}

export class UpdateAppVersionRequest implements UpdateAppVersionRequestInterface {
  private readonly operationSystem: string
  private readonly updateType: string
  private readonly appVersion: string
  private readonly versionValidation: string
  private readonly startDate: string // ISO8601: new Date().toISOString().slice(0, 19) + 'Z'

  constructor(operationSystem: string, updateType: string, appVersion: string, versionValidation: string, startDate: string) {
    this.operationSystem = operationSystem
    this.updateType = updateType
    this.appVersion = appVersion
    this.versionValidation = versionValidation
    this.startDate = startDate
  }

  public toPayload(): string {
    const data = {
      os: this.operationSystem,
      upgrade_type: this.updateType,
      version_value: this.appVersion,
      version_validation: this.versionValidation,
      start_date: this.startDate,
    }

    return JSON.stringify({
      ...data,
    })
  }
}
