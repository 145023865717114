import { Dropdown } from '@/app/infrastructures/misc/Constants/loyaltyProgram'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface';

export enum EnumUpdateAppVersionOS {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export enum EnumUpdateAppVersion {
  FORCE = 'FORCE',
  RECOMMEND = 'RECOMMEND'
}

export enum EnumVersionUpdate {
  LOWER_THAN = 'LOWER_THAN',
  EQUAL = 'EQUAL',
}

export enum EnumUpdateAppVersionStatus {
  PENDING = 'Pending',
  PUBLISHED = 'Published',
  CANCELLED = 'Cancelled',
}

export enum EnumUpdateAppVersionPublishSort {
  LATEST = 'latest_created',
  OLDEST = 'OLDEST',
}

export const listOS: Dropdown[] = [
  {
    label: 'Android',
    value: EnumUpdateAppVersionOS.ANDROID,
  },
  {
    label: 'iOS',
    value: EnumUpdateAppVersionOS.IOS
  },
]

export const listUpdate: Dropdown[] = [
  {
    label: 'Force',
    value: EnumUpdateAppVersion.FORCE,
  },
  {
    label: 'Recommend',
    value: EnumUpdateAppVersion.RECOMMEND
  },
]

export const listVersionValidation: Dropdown[] = [
  {
    label: 'LOWER_THAN',
    value: EnumVersionUpdate.LOWER_THAN,
  },
  {
    label: 'EQUAL',
    value: EnumVersionUpdate.EQUAL
  },
]

// LIST
export interface paramsInterface {
  page: number
  perPage: number
  sortBy: string
  filterBy: string
  keyword: string
}
export const filterOptions: DropDownLabelValue<string>[] = [
  {
    label: 'Android',
    value: EnumUpdateAppVersionOS.ANDROID,
  },
  {
    label: 'iOS',
    value: EnumUpdateAppVersionOS.IOS,
  },
  {
    label: 'Force',
    value: EnumUpdateAppVersion.FORCE,
  },
  {
    label: 'Recommended',
    value: EnumUpdateAppVersion.RECOMMEND,
  },
  {
    label: 'Pending',
    value: EnumUpdateAppVersionStatus.PENDING,
  },
  {
    label: 'Published',
    value: EnumUpdateAppVersionStatus.PUBLISHED,
  },
  {
    label: 'Cancelled',
    value: EnumUpdateAppVersionStatus.CANCELLED,
  }
]

export const sortOptions: DropDownLabelValue<string>[] = [
  {
    label: 'Latest Publish Date',
    value: EnumUpdateAppVersionPublishSort.LATEST,
  },
  {
    label: 'Old Publish Date',
    value: EnumUpdateAppVersionPublishSort.OLDEST,
  }
]
