













































import { Component, Prop, Vue } from 'vue-property-decorator'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import TimePicker from '@/app/ui/components/TimePicker/index.vue'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  inheritAttrs: false,
  components: {
    TimePicker,
    DateTimePickerV2,
    IconWarningTriangle,
  },
})
export default class UpdateAppVersionDateTimePicker extends Vue {
  @Prop({required: true}) private fieldLabel!: string
  @Prop() private fieldDescription!: string
  @Prop() private errorMessage!: string
  @Prop({default: false}) private isTimePicker!: boolean
  @Prop({default: false}) private required!: boolean
  @Prop({default: null}) private valueDate!: Date
  @Prop({default: null}) private valueTime!: Date
  @Prop({default: false}) private disabledTime!: boolean
  @Prop({default: false}) private disabledDate!: boolean
  @Prop({default: false}) private isErrorTime!: boolean
  @Prop({default: false}) private isErrorDate!: boolean

  dateValue: Date | null = null

  get isTodayDate(): boolean {
    return (
      !!this.valueDate &&
      this.valueDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
    )
  }

  get hoursNow(): number {
    return this.isOverThirtyMinute
      ? parseInt(Utils.formatDateWithIDLocale(new Date().toISOString(), 'HH')) +
      1
      : parseInt(Utils.formatDateWithIDLocale(new Date().toISOString(), 'HH'))
  }

  get hourOptions(): Array<string | number> {
    return this.isTodayDate
      ? Array.from(
        {
          length: 24 - (this.hoursNow - 1),
        },
        (_x, i) => i + (this.hoursNow - 1)
      )
      : Array.from({length: 24}, (_x, i) => i)
  }

  get isOverThirtyMinute(): boolean {
    return (
      parseInt(Utils.formatDateWithIDLocale(new Date().toISOString(), 'mm')) >
      30
    )
  }

  onInputTime(date: Date): void {
    if (date){
      this.dateValue = new Date(date)
      if (this.dateValue.getHours() === 0 && this.isTodayDate) {
        this.dateValue.setHours(this.hoursNow, date.getMinutes())
        this.$emit('input-time', new Date(this.dateValue))
      } else {
        this.dateValue.setHours(date.getHours(), date.getMinutes())
        this.$emit('input-time', new Date(this.dateValue))
      }
    } else {
      this.$emit('input-time')
    }
  }
}
