
import { Vue, Component, Watch } from 'vue-property-decorator'
import UpdateAppVersionDropdown from '@/app/ui/views/UpdateAppVersion/components/UpdateAppVersionDropdown/index.vue'
import UpdateAppVersionInput from '@/app/ui/views/UpdateAppVersion/components/UpdateAppVersionInput/index.vue'
import UpdateAppVersionDateTimePicker from '@/app/ui/views/UpdateAppVersion/components/UpdateAppVersionDateTimePicker/index.vue'
import * as constantsData from '@/app/infrastructures/misc/Constants/updateAppVersion'
import Button from '@/app/ui/components/Button/index.vue'
import { required, ValidationRule } from 'vuelidate/lib/validators'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import controller from '@/app/ui/controllers/UpdateAppVersionController'
import { Utils } from '@/app/infrastructures/misc'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { UpdateAppVersion } from '@/domain/entities/UpdateAppVersion'

interface ValidationsInterface {
  form: {
    operationSystem: {
      required: () => ValidationRule
    }
    updateType: {
      required: () => ValidationRule
    }
    appVersion: {
      required: () => ValidationRule
    }
    versionValidation: {
      required: () => ValidationRule
    }
    publishDate: {
      required: () => ValidationRule
    }
    publishTime: {
      required: () => ValidationRule
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    LoadingOverlay,
    Modal,
    Button,
    UpdateAppVersionDropdown,
    UpdateAppVersionInput,
    UpdateAppVersionDateTimePicker,
    ModalAction,
  },
})
export default class UpdateAppVersionEdit extends Vue {
  controller = controller
  constantsData = constantsData
  showModalConfirm = false
  showModalSuccess = false
  disableInput = false
  idTitle = ''
  valueDateAndTime = '-'

  form: {
    operationSystem: DropDownLabelValue<string> | undefined
    updateType: DropDownLabelValue<string> | undefined
    appVersion: string
    versionValidation: DropDownLabelValue<string> | undefined
    publishDate: Date | undefined
    publishTime: Date | undefined
  } = {
    operationSystem: undefined,
    updateType: undefined,
    appVersion: '',
    versionValidation: undefined,
    publishDate: undefined,
    publishTime: undefined,
  }

  modalActionData = {
    isCancel: false,
    title: '',
    description: '',
    textPrimary: '',
    textSecondary: '',
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        operationSystem: { required },
        updateType: { required },
        appVersion: { required },
        versionValidation: { required },
        publishDate: { required },
        publishTime: { required },
      },
    }
  }

  created(): void {
    controller.getOne(+this.$route.params.id)
  }

  @Watch('controller.detailData')
  onControllerDetailDataChange(value: {
    id: number,
    os: string,
    forceVersion: string,
    versionValidation: string,
    recommendVersion: string,
    publishDateTime: string,
    status: string,
    deletedAt: string | undefined,
    } | null
  ): void {
    if (value) {
      this.idTitle = `- ID ${value.id}`
      this.form.operationSystem = constantsData.listOS.find(
        item => item.value === value.os
      )
      this.form.updateType = constantsData.listUpdate.find(item => {
        const updateType =
          value.forceVersion !== '1.0.0'
            ? constantsData.EnumUpdateAppVersion.FORCE
            : constantsData.EnumUpdateAppVersion.RECOMMEND
        return item.value === updateType
      })
      this.form.versionValidation = constantsData.listVersionValidation.find(
        item => item.value === value.versionValidation
      )
      this.form.appVersion =
        value.forceVersion !== '1.0.0'
          ? value.forceVersion
          : value.recommendVersion
      this.form.publishDate = new Date(value.publishDateTime)
      this.form.publishTime = new Date(value.publishDateTime)
      this.disableInput = value.status.toLowerCase() !== constantsData.EnumUpdateAppVersionStatus.PENDING.toLowerCase()

      if (value.deletedAt || value.deletedAt !== '') {
        this.$notify({
          title: 'Update App Version already CANCELED',
          text:
            'This update app version schedule is already CANCELED and not editable',
          type: 'error',
          duration: 3000,
        })
      }
    }
  }


  getPublishDateAndTime(
    publishDate: Date | undefined,
    publishTime: Date | undefined
  ): string {
    let chooseDate = '-'
    if (publishDate && publishTime) {
      const theDate = Utils.setFormatDateTime(
        publishDate,
        publishTime.getHours(),
        publishTime.getMinutes()
      )
      chooseDate = Utils.formatDate(theDate, 'DD MMMM YYYY HH:mm')
    }
    return chooseDate
  }

  doActionModal(): void {
    if (this.modalActionData.isCancel) {
      this.$router.push('/update-app-version')
    } else {
      const todayDate = new Date()
      if (this.form.publishDate && this.form.publishTime) {
        const chooseDate = Utils.setFormatDateTime(
          this.form.publishDate,
          this.form.publishTime.getHours(),
          this.form.publishTime.getMinutes()
        )

        if (new Date(chooseDate) < todayDate) {
          this.$notify({
            title: 'Update New Update App Version Failed',
            text:
              'Publish date and time must be a later date and time than now',
            type: 'error',
            duration: 3000,
          })
          return
        }

        this.showModalConfirm = false
        controller.update({
          id: +this.$route.params.id,
          payload: {
            operationSystem: this.form.operationSystem?.value || '',
            updateType: this.form.updateType?.value || '',
            appVersion: this.form.appVersion,
            versionValidation: this.form.versionValidation?.value || '',
            publishDateTime: chooseDate,
          },
        })
      }
    }
  }

  @Watch('controller.isSave')
  onWatchSave(isSuccess: boolean): void {
    if (isSuccess) {
      this.showModalConfirm = false
      this.showModalSuccess = true
    }
  }

  showModalCancel(): void {
    this.modalActionData = {
      isCancel: true,
      title: 'Are You Sure You Want To Cancel Changes The Changes?',
      description: 'Data changes will not be saved',
      textPrimary: 'Yes',
      textSecondary: 'Check Again',
    }
    this.showModalConfirm = true
  }

  showModalSubmit(): void {
    this.modalActionData = {
      isCancel: false,
      title: 'Want to Schedule an Update Version?',
      description: '',
      textPrimary: 'Yes, Save',
      textSecondary: 'Check Again',
    }
    this.showModalConfirm = true
    this.valueDateAndTime = this.getPublishDateAndTime(
      this.form.publishDate,
      this.form.publishTime
    )
  }

  onCloseSuccessModal(): void {
    this.showModalSuccess = false
    this.$router.push('/update-app-version')
  }

  onInputDate(chooseDate: Date | undefined): void {
    this.form.publishDate = chooseDate
  }

  onInputTime(chooseTime: Date): void {
    this.form.publishTime = chooseTime
  }

  beforeDestroy(): void {
    controller.setDetailData(null)
  }
}
